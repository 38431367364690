.app-container {
  text-align: center;
  height: 100%;
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.slogan {
  margin: 0 0 1rem 0;
  border-bottom: 0.1rem solid #ffffff;
  border-top: 0.1rem solid #ffffff;
  width: 550px;
  padding: 20px 0;
}

.name {
  /* font-size: 2rem; */
  font-size: 1.6rem;
  line-height: 1.3;
  letter-spacing: 0.5rem;
  color: #ffffff;
  font-weight: 600;
}

.code {
  color: #ffffff;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  line-height: 2;
}

.div-color {
  color: #e12977;
  font-weight: bold;
}

.class-color {
  color: #c5e400;
  font-weight: bold;
}

.info-ul {
  display: flex;
  padding: 0;
}

.info-li {
  color: #ffffff;
  min-width: 4.5rem;
  height: 2.75rem;
  line-height: 2.75rem;
  padding: 0 1.25rem 0 1.45rem;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  list-style-type: none;
  border: 0.01rem solid #ffffff;
  cursor: pointer;
}

.link-contact {
  text-decoration: none;
}

.info-li:hover {
  background-color: rgba(255, 255, 255, 0.075)
}

.pfp {
  border-radius: 50%;
  width: 152px;
  height: 152px;
  margin-bottom: 30px;
}

.modal-container {
  display: flex;
  padding: 0;
  color: #ffffff;
}

.modal-style {
  background-color: rgba(27, 31, 34, 1);
  padding: 20px;
  color: #eeeeee;
  width: 570px;
  height: 564px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  overflow-y: auto;
}

.modal-style::-webkit-scrollbar {
  -webkit-appearance: none;
}

.modal-style::-webkit-scrollbar:vertical {
  width: 11px;
}

.modal-style::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #333333;
  background-color: #4d5153;
}

.msc {
  height: fit-content;
}

.responsive-center {
  display: flex;
  flex-direction: column;
}

.modal-close {
  border: none;
  background-color: rgba(27, 31, 34, 1);
  color: #ffffff;
  border-radius: 100%;
  font-size: 3.25rem;
  font-weight: 100;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.footer {
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 5px;
  right: 0;
  letter-spacing: 0.2rem;
  font-size: 0.6rem;
  opacity: 0.35;
}

.responsive-image {
  width: 570px;
  height: 166px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 1.5rem;
  line-height: 1.4;
  letter-spacing: 0.5rem;
  color: #ffffff;
  font-weight: 600;
  margin-left: 5px;
  border-bottom: 2px solid #ffffff;
  width: fit-content;
  padding-bottom: 5px;
}

.contact-info {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  margin: 0 0 1rem 0;
  letter-spacing: 0.2rem;
  line-height: 5.0;
}

.contact {
  list-style-type: none;
}

.center-contact {
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
}

.contact-margin {
  align-self: center;
  margin-bottom: 30px;
}

.li-no-indent {
  margin: 0;
  padding: 0;
}

@media(max-height: 660px){
  .app {
    justify-content: flex-start;
  }
}

@media(max-width: 620px){
  .modal-style {width: 90%}

  .responsive-image {
    width: 100%;
    height: 100%
  }

  .msc {
    height: auto;
    width: 100%;
  }
}

@media(max-width: 600px){
  .slogan {width: 75%}
  .info-ul {flex-direction: column;}
  .footer {position: relative;}
  .pfp {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }
  .slogan-align {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


@media(max-width: 437px){
  .name {font-size: 1rem}
}

@media(max-width: 437px){
  .name {
    letter-spacing: 0.2rem;
  }
}

@media(max-width: 414px){
    .modal-style {
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      overflow-y: auto;
    }

    .mobile-padding {
      padding: 20px;
    }
}

@media(max-width: 320px){
  .contact-margin {margin-bottom: 100px;}
}